import config from "../config";
import { makeRequest } from "../util/fetch";

export const betaSignUp = ({
  // Required
  email,
  firstName,
  howDidYouHearAboutUs,
  lastName,
  primaryUseCase,
  subscribedToNewsletter,
  ffEmails,
  yourStory,
}) => {
  const url = `${config.app.url}/api/v1/betaSignUp`;
  return makeRequest({
    url,
    method: 'POST',
    body: {
      email,
      firstName,
      howDidYouHearAboutUs,
      lastName,
      primaryUseCase,
      subscribedToNewsletter,
      ffEmails,
      yourStory,
    },
  }).then(data => data.email);
};

export const updateCustomer = ({
  address,
  email,
  firstName,
  id,
  lastName,
  middleName,
  phone,
  userAuthToken,
}) => {
  const url = `${config.app.url}/api/v1/customers/${id}`;
  return makeRequest({
    body: { address, email, firstName, id, lastName, middleName, phone },
    method: "PUT",
    token: userAuthToken,
    url,
  }).then(data => data);
};

