import config from "../config";
import { makeRequest } from "../util/fetch";

export const signUp = ({ email, firstName, lastName, password, reCAPTCHAToken }) => {
  const url = `${config.app.url}/api/v1/signUp`;
  return makeRequest({
    url,
    method: "POST",
    body: { email, firstName, lastName, password, reCAPTCHAToken },
  }).then(data => { });
};

export const signUpWithOAuth = ({ email, firstName, lastName, idToken }) => {
  const url = `${config.app.url}/api/v1/signUpWithOAuth`;
  return makeRequest({
    url,
    method: "POST",
    body: { email, firstName, lastName, idToken },
  }).then(data => {
    return data;
  });
};

export const getFreshAccessToken = (refreshToken) => {
  const url = `${config.app.url}/api/v1/refreshAccess`;
  return makeRequest({
    url,
    method: "POST",
    body: { refreshToken },
  }).then(data => {
    return data.id_token;
  });
};

export const getBearer = ({
  // Required
  email,
  password,
}) => {
  const url = `${config.app.url}/api/v1/signIn`;
  return makeRequest({
    url,
    method: "POST",
    body: { email, password },
  }).then(resp => {
    return { FBToken: resp.token, BearerToken: resp.bearerToken };
  });
};

export const getUser = ({ userAuthToken }) => {
  const url = `${config.app.url}/api/v1/customers/me`;
  return makeRequest({
    url,
    method: "GET",
    token: userAuthToken,
  });
};
