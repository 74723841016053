import React from "react";
import Helmet from "react-helmet";
import config from "../../src/config";

export const TitleComponent = ({ title, canDesc, metaTitle, metaDescription, structuredData }) => {
  var defaultTitle = "Make family and friends lending formal - set up loan agreement, payments and reminders."
  var frontendPreFix = config.app.appUrl;
  var canonicalHrefLink = frontendPreFix + (canDesc ? canDesc : "");
  return (
    <Helmet>
      <link rel="canonical" href={canonicalHrefLink} />
      {metaTitle && <title>{metaTitle ? metaTitle : (title ? title : defaultTitle)}</title>}
      {metaTitle && <meta
        name="title"
        content={metaTitle}
      />}
      {metaDescription && <meta
        name="description"
        content={metaDescription}
      />}
      {structuredData &&
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      }
    </Helmet >
  );
};

export default TitleComponent;
