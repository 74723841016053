import { Link } from "react-router-dom";
import SectionButton from "../../components/SectionButton";

export const renderCTAs = () => {
    return (<>
        <div className="ctas columns">
            < div className="column" >
                <Link to="/know">
                    <SectionButton
                        backgroundColor={"blue"}
                    >
                        Read More
                    </SectionButton>
                </Link>
            </div >

            <div className="column">
                <Link to="/get-started">
                    <SectionButton
                        backgroundColor={"green"}

                    >
                        Get Started
                    </SectionButton>
                </Link>
            </div>
            <div className="column">
                <Link to="/">
                    <SectionButton
                        backgroundColor={"orange"}

                    >
                        How Namma Works
                    </SectionButton>
                </Link>
            </div>
        </div >
    </>);
}

export const renderGiftCTAs = () => {
    return (<>
        <div className="ctas columns">


            <div className="column">
                <Link to="/gift-terms">
                    <SectionButton
                        backgroundColor={"green"}

                    >
                        Start a Gift Request
                    </SectionButton>
                </Link>
            </div>
            <div className="column">
                <Link to="/product-gift">
                    <SectionButton
                        backgroundColor={"orange"}

                    >
                        How Namma Gifting Works
                    </SectionButton>
                </Link>
            </div>
        </div >
    </>);
}

export const renderDisclaimer = () => {
    return <>
        <div display="block">
            <p className="know_text">
                <i>
                    Disclaimer: Namma is not your legal advisor, tax advisor or
                    lawyer. Our blogs are based on product research done by our team
                    with our customers and our service is meant to make borrowing
                    and lending between family and friends less awkward and more
                    uplifting.
                </i>
            </p>
        </div>
    </>;
}

export const renderAboutNamma = () => {
    return <>
        <p className="about">
            <a href="/" className="link"
                target="_blank"
                rel="noopener noreferrer">
                <br />
                About Namma: We are a platform to help you lend and borrow money from your family and friends legally and with third party management services.
            </a>
        </p>
    </>
}

export function renderCrossLinkSection(headerText, text, buttonText, buttonTo) {
    return (
        <><p className="spacer-thin"></p>
            <div className="container-basic background-light-green corners-rounded">
                <p className="text-subheading color-eggplant">
                    {headerText}
                </p>
                <p className="spacer-thin"></p>
                <p className="text-body-small color-eggplant">
                    {text}
                </p>
                <p className="spacer-thick"></p>
                {buttonTo && buttonText && < Link to={buttonTo}>
                    <button className="button button-green align-left">
                        {buttonText}
                    </button>
                </Link>}
                <p className="spacer-thin"></p>
            </div >
            <p className="spacer-thick"></p>
        </>);
}

export function renderTakeAwaySection(headerText, text) {
    return (
        <><p className="spacer-thin"></p>
            <div className="container-slim  border-left border-blue">

                <p className="text-subheading align-left color-blue ">
                    {headerText}
                </p>
                <p className="spacer-thin"></p>
                <p className="text-body color-dusk align-left color-blue">
                    {text}
                </p>
                <p className="spacer-thin"></p>
            </div>
            <p className="spacer-thick"></p>
        </>);
}

export function renderHighlight(headerText, heading, text) {
    return (
        <><p className="spacer-thin"></p>
            <div className="container-basic content-indented  background-highlight corners-rounded">
                <p className="text-body-small color-dusk">
                    {headerText}
                </p>
                <p className="spacer-thin"></p>
                <p className="text-subheading color-eggplant">
                    {heading}
                </p>
                <p className="spacer-thin"></p>
                <p className="text-body-small color-eggplant">
                    {text}
                </p>

            </div >
            <p className="spacer-thick"></p>
        </>);
}

export function renderBreadCrumb(breadCrumb1, breadCrumb1Link, current) {
    return <>
        <p className="text-body-small text-breadCrumbLink align-left">
            <a className="text-breadCrumbLink" href={breadCrumb1Link}>{breadCrumb1} </a>
            {">"} {current}
            <p className="spacer-thin">
            </p>
        </p></>;
}
export function renderHyperLinkTo(text, toAnchor) {
    return <><p className="text-body-small text-hyperlink align-left"><a href={toAnchor}>{text}</a><p className="spacer-thin"></p></p></>;
}
export function renderTOCLinkTo(text, toAnchor) {
    return <><p className="text-body-small text-tocItem align-left"><a className="color-dusk" href={toAnchor}>{text}</a><p className="spacer-thin"></p></p></>;
}

export function renderH1(text) {
    return <><h1 className="text-h1 align-left">{text}</h1><p className="spacer-thin"></p></>;
}

export function renderH2(text, toAnchor) {
    return <><h2 id={toAnchor} className="text-subheading align-left">{text}</h2><p className="spacer-thin"></p></>;
}

export function renderH3(text, toAnchor) {
    return <><h3 id={toAnchor} className="text-h2 align-left">{text}</h3><p className="spacer-thin"></p></>;
}

export function renderParagraph(text) {
    return <><p className="text-body color-dusk align-left">{text}</p><p className="spacer-thin"></p></>
        ;
}


export function renderExternalLink(linkText, linkTo) {
    return <p className="text-body-small text-hyperlink align-left">
        <a
            href={linkTo}
            className="link"
            target="_blank"
            rel="noopener noreferrer"
        >{linkText}
        </a>
    </p>;
}