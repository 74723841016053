export default {
  development: {
    oAuthWebClientId: '95833919391-4v104ps1ss5f5t5e9mav8tjtoqutjag0.apps.googleusercontent.com',
    url: "http://localhost:3010",
    appUrl: "http://localhost:3000",
    reCaptchaSiteKey: "6LeNUSseAAAAAKzQNaPYI-pzRU4TQIN655dtaQbM",
    squareMonthlySubLink: "https://checkout.square.site/merchant/ML66EZ8JXRYZ5/checkout/6CTY6GFL7OZIBPHXXDN3JA6W",
  },
  test: {
    oAuthWebClientId: '95833919391-4v104ps1ss5f5t5e9mav8tjtoqutjag0.apps.googleusercontent.com',
    url: "https://backend-dot-namma-test.uc.r.appspot.com",
    appUrl: "https://namma-test.uc.r.appspot.com",
    reCaptchaSiteKey: "6LeNUSseAAAAAKzQNaPYI-pzRU4TQIN655dtaQbM",
    squareMonthlySubLink: "https://checkout.square.site/merchant/ML66EZ8JXRYZ5/checkout/6CTY6GFL7OZIBPHXXDN3JA6W",
  },
  production: {
    oAuthWebClientId: '375074232143-8gppffnk1gne8ebbehfvthljbc1o9msr.apps.googleusercontent.com',
    url: "https://backend-dot-namma-251111.appspot.com",
    appUrl: "https://namma.co",
    reCaptchaSiteKey: "6LeNUSseAAAAAKzQNaPYI-pzRU4TQIN655dtaQbM",
    squareMonthlySubLink: "https://checkout.square.site/merchant/ML66EZ8JXRYZ5/checkout/6CTY6GFL7OZIBPHXXDN3JA6W",
  },
};
