import React from 'react';
import './styles.scss';

function SectionButton(props) {
  const {
    backgroundColor,
    fullWidth,
    parentColor,
    size,
    color,
    state,
    // Passed to button element
    ...otherProps
  } = props;

  return (
    <button
      className={
        'button' +
        ([
          'primary',
          'info',
          'success',
          'warning',
          'danger',
          'black',
          'dark'
        ].includes(parentColor)
          ? ` is-${parentColor} is-inverted`
          : '') +
        (['white', 'light'].includes(parentColor) ? ' is-primary' : '') +
        (backgroundColor ? ` is-${backgroundColor}-btn` : '') +
        (size ? ` is-${size}` : '') +
        (state ? ` is-${state}` : '') +
        (fullWidth ? ' is-fullwidth' : '') + 
        (color ? ` ${color}-text` : '')
      }
      {...otherProps}
    >
      {props.children}
    </button>
  );
}

export default SectionButton;
