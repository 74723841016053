import { Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  snackbarError: {
    backgroundColor: "#E06956",
    color: "#fff"
  },
  snackbarSuccess: {
    backgroundColor: "#429D84",
    color: "#fff"
  },
  close: {
    padding: ".5rem",
    cursor: "pointer"
  }
}));

function ConsecutiveSnackbars(props) {
  const { notification } = props;
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (notification.show && messageInfo !== notification.message) {
      const { message } = notification;
      setMessageInfo(message)
      setOpen(true);
    }
  }, [notification]);

  const handleClose = () => {
    setOpen(false);
    setMessageInfo(undefined)
  };

  const classes = useStyles();
  const rootClasses = props.notification.error ? classes.snackbarError : classes.snackbarSuccess;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id",
        classes: {
          root: rootClasses
        }
      }}
      message={<span id="message-id"> {messageInfo}</span>}
      action={[
        <div className={classes.close} onClick={handleClose}>
          <i className="far fa-times-circle"></i>
        </div>
      ]}
    />
  );
}

const mapStateToProps = state => {
  return {
    notification: state.notification
  };
};

export default connect(mapStateToProps)(ConsecutiveSnackbars);
