import {
  ACCEPT_TERMS_OF_SERVICE,
  CREATE_NEW_LOAN_REQUEST,
  DELETE_LOAN_REQUEST,
  FINALIZE_LOAN_REQUEST,
  FRESH_LOAN_REQUEST,
  GET_LOAN_DOCUMENT,
  GET_LOAN_REQUEST,
  GET_LOAN_REQUESTS,
  REJECT_REASON,
  REVIEWED_LOAN_REQUEST,
  SEND_LOAN_TO_RECIPIENT,
  SIGN_LOAN_REQUEST,
  UPDATE_LOAN_REQUEST
} from "./types";

export const currentLoanRequestInitialState = {
  loanProposalDetails: {},
  recipientRequestDetails: {},
  servicePrefDetails: {}
};

export const initialState = {
  borrowerLoans: [],
  currentLoanRequest: currentLoanRequestInitialState,
  lenderLoans: []
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case GET_LOAN_REQUESTS:
      return {
        ...state,
        lenderLoans: payload.lenderLoans,
        borrowerLoans: payload.borrowerLoans
      };
    case FRESH_LOAN_REQUEST:
    case DELETE_LOAN_REQUEST:
      return {
        ...state,
        currentLoanRequest: currentLoanRequestInitialState
      };
    case CREATE_NEW_LOAN_REQUEST:
      return {
        ...state,
        currentLoanRequest: payload
      };
    case UPDATE_LOAN_REQUEST:
      return {
        ...state,
        currentLoanRequest: payload
      };
    case SIGN_LOAN_REQUEST:
      return {
        ...state,
        currentLoanRequest: payload
      };
    case GET_LOAN_REQUEST:
      return {
        ...state,
        currentLoanRequest: payload
      };
    case ACCEPT_TERMS_OF_SERVICE:
    case FINALIZE_LOAN_REQUEST:
    case GET_LOAN_DOCUMENT:
    case REJECT_REASON:
    case REVIEWED_LOAN_REQUEST:
    case SEND_LOAN_TO_RECIPIENT:

    default:
      return state;
  }
};

export default reducer;