const prefix = type => `@GIFTS/${type}`;

export const ACCEPT_TERMS_OF_SERVICE = prefix("ACCEPT_TERMS_OF_SERVICE");
export const CREATE_NEW_GIFT_REQUEST = prefix("CREATE_NEW_GIFT_REQUEST");
export const FINALIZE_GIFT_REQUEST = prefix("FINALIZE_GIFT_REQUEST");
export const FRESH_GIFT_REQUEST = prefix("FRESH_GIFT_REQUEST");
export const GET_GIFT_REQUESTS = prefix("GET_GIFT_REQUESTS");
export const GET_GIFT_DOCUMENT = prefix("GET_GIFT_DOCUMENT");
export const SIGN_GIFT_REQUEST = prefix("SIGN_GIFT_REQUEST");
export const GET_GIFT_REQUEST = prefix("GET_GIFT_REQUEST");
export const REJECT_REASON = prefix("REJECT_REASON");
export const REVIEWED_GIFT_REQUEST = prefix("REVIEWED_GIFT_REQUEST");
export const SEND_GIFT_TO_RECIPIENT = prefix("SEND_GIFT_TO_RECIPIENT");
export const UPDATE_GIFT_REQUEST = prefix("UPDATE_GIFT_REQUEST");
export const DELETE_GIFT_REQUEST = prefix("DELETE_GIFT_REQUEST");
