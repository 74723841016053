import fetch, { Headers } from "node-fetch";

export const ERROR_TYPES = {
  BAD_REQUEST: "BAD_REQUEST",
  CONFLICT: "CONFLICT",
  FORBIDDEN: "FORBIDDEN",
  GENERIC: "GENERIC",
  NOT_FOUND: "NOT_FOUND",
};

export const checkStatusAndParse = response => {
  if (response.status >= 200 && response.status < 300) {
    const contentType = response.headers.get("content-type");

    if (contentType) {
      if (contentType.indexOf("application/json") !== -1) {
        return response.json();
      }

      if (contentType.indexOf("text/csv") !== -1) {
        return response.blob();
      }
    }

    return response;
  }

  const error = { response };
  switch (response.status) {
    case 400:
      error.type = ERROR_TYPES.BAD_REQUEST;
      break;
    case 403:
      error.type = ERROR_TYPES.FORBIDDEN;
      break;
    case 404:
      error.type = ERROR_TYPES.NOT_FOUND;
      break;
    case 409:
      error.type = ERROR_TYPES.CONFLICT;
      break;
    default:
      error.type = ERROR_TYPES.GENERIC;
  }

  throw error;
};

export const makeRequest = ({
  url,
  method,
  token,
  headers = {},
  body = {},
}) => {
  const upperCaseMethod = method.toUpperCase();

  return fetch(url, {
    method: upperCaseMethod,
    mode: "cors",
    headers: new Headers({
      Authorization: `${token}`,
      "Content-Type": "application/json",
      ...headers,
    }),
    body:
      upperCaseMethod === "POST" ||
        upperCaseMethod === "PATCH" ||
        upperCaseMethod === "PUT"
        ? JSON.stringify(body)
        : undefined,
  }).then(checkStatusAndParse);
};
