import React from "react";
import TitleComponent from "../../components/TitleComponent";
import { renderBreadCrumb, renderCTAs, renderDisclaimer, renderH1, renderH2, renderHighlight, renderParagraph, renderTOCLinkTo, renderTakeAwaySection } from "./shared";
import "./styles.scss";
function Amortization() {

    const renderTOC = () => {
        return (
            <>
                <div className="content-non-scrollable mobile-invisible">
                    <div className="text-tocHeader align-left">TABLE OF CONTENTS</div>
                    <div className="spacer-thin"></div>
                    {renderTOCLinkTo("Why are loan payments equal over the payback period?", "#answer1")}
                    {renderTOCLinkTo("So, how do standard amortization payments work?", "#answer2")}
                    {renderTOCLinkTo("How does Namma calculate principal and interest component in every payment?", "#answer3")}
                    {renderTOCLinkTo("How does Namma calculate the outstanding loan balance over time?", "#answer4")}
                </div></>);
    }

    return (
        <>
            <TitleComponent
                title="Namma: How are loans amortized, payments calculated, and principal and interest calculated"
                canDesc="/know/loan-amortization-calculator-interest-principal-schedule"
                metaTitle="How are loans amortized, payments calculated, and principal and interest calculated | Namma"
                metaDescription="How does Namma calculate amortization on your loan, with a breakdown on interest and principal per payment"
            />
            <div className="container-basic">
                <div className="columns">
                    <div className="column is-2">
                        {renderTOC()}
                    </div>
                    <div className="column is-10">
                        <div className="container-wide content-centered">
                            {renderBreadCrumb("Product", "/product", "Loan Amortization and management")}
                            {renderH1("Amortization for loans - calculations of the principal, interest and outstanding loan balances")}
                            {renderParagraph("Namma generates an amortized loan schedule for your friends/family loan which you can follow along and report on as the loan payback happens. We would like to help you understand how these payments are calculated and changed over time. For that, let us break down the process for you with some fundamentals at first."
                            )}
                            {renderParagraph("Generally, a loan is defined with a set of parameters: a loan amount, a repayment date, an annual interest rate, and a cadence at which the repayments will be made (in our case it is monthly). Based on these parameters Namma amortizes your schedule over time with a standard loan payment that will be made each month back to the lender. ")}

                            {renderTakeAwaySection("First, the definition of 'Amortization':", "Amortization is defined as ‘the action or process of gradually writing off the initial cost of an asset.’ For a loan, this means splitting a loan payback into regular payments over time, such that with every payment a little bit of your loan is paid back. When you are paying back a lender, you are paying them interest over time which is factored into each of these payments along with paying back some of the principal.")}

                            <div className="spacer-thin"></div>
                            <div>
                                {renderH2("Why do we not pay back principal first?", "answer1")}
                                {renderParagraph("Now if you were a novice to loans, a very simple method of loan payback comes to mind first. Let’s just spread the loan payback over monthly payments of an equal amount of principal, which will total to the loan amount over the months of payback.")}

                                {renderParagraph("In such a scenario every payment still needs to have the interest component which will always be calculated based on the outstanding balance at every month based on prior month's outstanding loan balance. Clearly interest changes over time based on this logic.",)}
                                {renderHighlight("🌟 Lets explain with an example", "Equal Principal payment example", "For a $60K loan over 10 years at 5% APR, if we decided to pay back principal evenly every month, the borrower could end up paying $1000K per month. Now, the interest to be paid back each month still needs to be calculated. At the start the loan balance is $60K, so the interest for this amount is $250 and that could be put together to make the first payment of $1250. But that would not be the case for the 2nd month. Since the loan balance still remaining is $59000, the interest will be lower than $250, it will be $245.83, thus making the monthly payment due to $1245.83. Loan payments will in such a scenario keep reducing over time. ")
                                }
                                {renderParagraph("Therefore there are two implications:")}
                                {renderTakeAwaySection("Implication #1", "Each payment is uneven, not giving clarity to the borrower/lender on payment and not allowing for clear budgeting and expectation.")}
                                {renderTakeAwaySection("Implication #2", "Payment amounts are higher up front than later - not working to the borrower’s advantage, i.e. what if they don’t have the capacity to start paying heavier payments up front?")}
                                {renderParagraph("Therefore for these reasons, standard amortization is preferred.")}

                            </div>
                            <div className="spacer-thin"></div>
                            <div >
                                {renderH2("So, how do standard amortization payments work?", "answer2")}
                                {renderParagraph("Standard amortization is where a loan payment is the same for every payback duration over the lifetime of the loan. This amount is arrived at by calculating what the lender should receive monthly (the annuity amount) so that over the period of time their investment value equates to what they would make elsewhere (by using lumpsum formula). Remember that the lender is lending you money and therefore they should benefit the same way as if they would, if they were investing this money in an interest bearing account with similar terms.")}
                                {renderParagraph("Namma calculates your monthly payment with the following formula (which is the standard in the industry) based on the following inputted by you in your loan terms: loan amount, interest rate, payment duration.")}
                                <div className="spacer-thin"></div>
                                <img src="../blog/PaymentCalc.svg" alt="Amortization Monthly payment formula"></img>
                                <div className="spacer-thin"></div>
                                {renderParagraph("Where r is the monthly interest rate, termInMonths is the number of months you will be spreading the payment over.")}
                                {renderHighlight("🌟 Lets explain with an example", "Amortized payment for a loan", "For a $60K loan over 10 years at 5% APR, the monthly payment for all the 60 months will be standardized to $1132.27 to give the final lumpsum amount to the lender of $77001.52.")}
                            </div>
                            <div className="spacer-thin"></div>
                            <div>
                                {renderH2("How does Namma calculate principal and interest component in every payment?", "answer3")}
                                {renderParagraph("Now every payment that is paid back with standard amortization, a part of it goes to principal and a part of it goes to interest. An interest is deducted every month that is based on the outstanding balance at that time. The rest is applied towards principal. Since outstanding balance is higher at the beginning and gets paid down over time, the interest portion of the payment is higher in the beginning since it is derived by multiplying the interest for that period by the outstanding balance.")}
                                {renderParagraph("With Namma, if you end up making a payment that is higher than your monthly payment amount, that extra amount goes towards your principal thus reducing your outstanding loan balance for the month. If you end up making a payment lower than the monthly payment, first interest is taken from that payment and only the remainder is applied towards your principal. Your outstanding balance is reduced by the payment amount and the remaining balance is used to calculate interest for next month.")}
                                {renderHighlight("🌟 Lets explain with an example", "Principal and interest in each payment", "For a $60K loan over 10 years at 5% APR, at the first month, the outstanding loan balance is $60250 since the interest for the first month has been added. From the first payment, an amount of $250 will go towards interest, $882.27 towards principal and outstanding loan balance will be reduced to $59117.73. And thus the logic follows for the following months.")}
                            </div>
                            <div className="spacer-thin"></div>
                            <div>
                                <img src="../blog/payEarly.png" alt="Namma - Outstanding Balance over time with extra payments"></img>
                                <div className="spacer-thin"></div>
                                {renderH2("How does Namma calculate the outstanding loan balance over time?", "answer4")}
                                {renderParagraph("When you define your loan terms with Namma you decide a repayment date. Our repayment periods are set monthly. At the first repayment date, your outstanding loan balance is the loan amount you lent compounded for one month’s interest only, regardless of how many months post loan start date you set your repayment date.")}
                                {renderParagraph("Outstanding loan balance is changed on every repayment date that happens monthly. We increase the outstanding balance by compounded the current with one month's interest.")}
                                {renderHighlight("🌟 Lets explain with an example", "Outstanding Loan Balance Calculation Example", "For a $60K loan over 10 years at 5% APR, outstanding loan balance at the 1st repayment date is $60250, and the 2nd repayment date it would be $60,501.04 (i.e. if the 1st repayment was not made).")}
                            </div>

                            <div className="spacer-thin"></div>
                            {renderParagraph("We are keeping in sync with how the industry calculates loan payments since that process is fair, standard and works best for both the borrower and lender. We try to be as transparent as possible giving you clarity with each payment on how much was paid towards principal and how much towards the")}
                            <div className="spacer-thick"></div>
                            <div>
                                {renderCTAs()}
                                {renderDisclaimer()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Amortization;
