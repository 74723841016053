import appConfig from "./app.js";
import firebaseConfig from "./firebase.js";

const getConfig = () => {
  const env = process.env.REACT_APP_DEPLOY_ENV || "development";
  return {
    app: appConfig[env],
    firebase: firebaseConfig[env],
    environment: env
  };
};

export default getConfig();
