import {
  AUTHENTICATED,
  CREATE_USER,
  RESET_PASSWORD, SIGN_IN, SIGN_IN_ERROR,
  SIGN_IN_SUCCESS, SIGN_OUT,
  UNAUTHENTICATED,
  UPDATE_USER,
  UPDATE_USER_PROFILE
} from "./types";

export const initialState = {
  // For authorization state, undefined signifies that we don't know
  // if the user is authenticated (because the auth hasn't resolved
  // yet). null signifies that a user is *not* authenticated. For
  // most uses, this distinction won't matter, but it can be useful
  // for allowing us to wait until auth resolves before kicking a
  // user to a sign in page.
  address: {},
  auth: undefined,
  authError: null,
  phone: "",
  user: null,
  userAuthToken: null,
  hasVerifiedEmail: null
};

const setAddress = payload =>
  payload.address === null
    ? {
      line1: "",
      line2: "",
      zipCode: "",
      city: "",
      state: ""
    }
    : payload.address;

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case AUTHENTICATED:
      return {
        ...state,
        ...payload
      };
    case UNAUTHENTICATED:
      return {
        auth: false,
        user: null,
        userAuthToken: null
      };
    case UPDATE_USER:
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        ...payload,
        authError: null,
        address: setAddress(payload)
      };
    case SIGN_IN:
      return {
        ...state,
        authError: null
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        ...payload,
        address: setAddress(payload)
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        authError: payload
      };
    case SIGN_OUT:
      return {
        user: null,
        userAuthToken: null
      };
    case CREATE_USER:
    case RESET_PASSWORD:
    default:
      return state;
  }
};

export default reducer;
