import { createStore, applyMiddleware, compose } from "redux";
import config from "../config";
import thunk from "redux-thunk";
import { initializeApp } from 'firebase/app';

import rootReducer from "./rootReducer";
import { observeUserAuth } from "./user/actions";

initializeApp(config.firebase);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createConfiguredStore = () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk.withExtraArgument()))
  );
  store.dispatch(observeUserAuth());
  return store;
};

export default createConfiguredStore;
