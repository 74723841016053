import config from "../config";
import { makeRequest } from "../util/fetch";

export const createLoanRequest = ({
  initiatingCustomerId,
  initiatorRequestDetails,
  loanProposalDetails,
  recipientRequestDetails,
  requestType,
  status,
  userAuthToken,
}) => {
  const url = `${config.app.url}/api/v1/loanRequest`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "POST",
    body: {
      initiatingCustomerId,
      initiatorRequestDetails,
      loanProposalDetails,
      recipientRequestDetails,
      requestType,
      status,
    },
  });
};

export const getExecutedLoan = ({ userAuthToken, loanID }) => {
  const url = `${config.app.url}/api/v1/loans/${loanID}`;
  return makeRequest({
    method: "GET",
    token: userAuthToken,
    url,
  }).then(data => data);
};

export const fetchLoanPayments = ({ userAuthToken, loanID }) => {
  const url = `${config.app.url}/api/v1/loans/${loanID}/payments`;
  return makeRequest({
    method: "GET",
    token: userAuthToken,
    url,
  }).then(data => data);
};

export const getLoanDocument = ({ userAuthToken, loanDocumentID }) => {
  const url = `${config.app.url}/api/v1/loanDocuments/${loanDocumentID}`;
  return makeRequest({
    method: "GET",
    token: userAuthToken,
    url,
  }).then(data => data);
};

export const getLoanRequest = ({ userAuthToken, loanRequestID }) => {
  const url = `${config.app.url}/api/v1/loanRequest/${loanRequestID}`;
  return makeRequest({
    method: "GET",
    token: userAuthToken,
    url,
  }).then(data => data);
};

export const updateLoanRequest = ({ loanDetails, userAuthToken }) => {
  const url = `${config.app.url}/api/v1/loanRequest/${loanDetails.id}`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "PATCH",
    body: { ...loanDetails },
  }).then(data => data);
};

export const claimRecipient = ({ loanRequestID, userAuthToken }) => {
  const url = `${config.app.url}/api/v1/loanRequest/${loanRequestID}/claimRecipient`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "POST",
    body: {},
  }).then(data => data);
};

export const getLoanRequestsForUser = ({ customerId, userAuthToken }) => {
  const url = `${config.app.url}/api/v1/loanRequests?customerId=${customerId}`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "GET",
  }).then(data => sortLoans(customerId, data));
};

export const reviewLoanRequest = ({ loanRequestId, userAuthToken }) => {
  const url = `${config.app.url}/api/v2/loanRequests/${loanRequestId}/review`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "POST",
  }).then(data => data);
};

const sortLoans = (customerId, loans) => {
  const lenderLoanBorrowStatus = loans.filter(
    loan =>
      loan.initiatingCustomerId !== customerId && loan.requestType === "borrow",
  );
  const lenderLoanLendStatus = loans.filter(
    loan =>
      loan.initiatingCustomerId === customerId && loan.requestType === "lend",
  );
  const borrowerLoansLendStatus = loans.filter(
    loan =>
      loan.initiatingCustomerId !== customerId && loan.requestType === "lend",
  );
  const borrowerLoansBorrowStatus = loans.filter(
    loan =>
      loan.initiatingCustomerId === customerId && loan.requestType === "borrow",
  );
  const borrowerLoans = borrowerLoansLendStatus.concat(
    borrowerLoansBorrowStatus,
  );
  const lenderLoans = lenderLoanBorrowStatus.concat(lenderLoanLendStatus);

  return {
    borrowerLoans: {
      executed: borrowerLoans.filter(loan => loan.status === "executed"),
      review: borrowerLoans.filter(loan => loan.status === "review"),
      draft: borrowerLoans.filter(loan => loan.status === "draft"),
      rejected: borrowerLoans.filter(loan => loan.status === "rejected"),
    },
    lenderLoans: {
      executed: lenderLoans.filter(loan => loan.status === "executed"),
      review: lenderLoans.filter(loan => loan.status === "review"),
      draft: lenderLoans.filter(loan => loan.status === "draft"),
      rejected: lenderLoans.filter(loan => loan.status === "rejected"),
    },
  };
};

export const sendTermsOfService = ({ loanRequestId, userAuthToken }) => {
  const url = `${config.app.url}/api/v1/loanRequests/${loanRequestId}/signTos`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "POST",
  }).then(data => data);
};

export const getSignURL = ({ loanRequestId, userAuthToken }) => {
  const url = `${config.app.url}/api/v1/loanRequests/${loanRequestId}/getSignatureUrl`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "POST",
  }).then(data => data);
};

export const sendLoanRequest = ({
  loanRequestId,
  updateParty,
  userAuthToken,
}) => {
  const url = `${config.app.url}/api/v1/loanRequests/${loanRequestId}/send`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "POST",
    body: { updateParty },
  }).then(data => data);
};

export const signLoanRequest = ({
  userAuthToken,
  loanRequestId,
  signatureData
}) => {
  const url = `${config.app.url}/api/v2/loanRequests/${loanRequestId}/sign`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "POST",
    body: { signatureData },
  }).then(data => data);
}

export const finalizeLoan = ({ loanRequestId, userAuthToken }) => {
  const url = `${config.app.url}/api/v2/loanRequests/${loanRequestId}/finalize`
  return makeRequest({
    url,
    token: userAuthToken,
    method: "POST",
  }).then(data => data);
};

export const rejectLoanReason = ({
  loanRequestId,
  userAuthToken,
  rejectionReason,
}) => {
  const url = `${config.app.url}/api/v1/loanRequests/${loanRequestId}/reject`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "POST",
    body: { rejectionReason },
  }).then(data => data);
};

export const deleteLoanRequest = ({
  loanRequestId,
  userAuthToken,
}) => {
  const url = `${config.app.url}/api/v1/loanRequests/${loanRequestId}`;
  return makeRequest({
    url,
    token: userAuthToken,
    method: "DELETE",
  }).then(data => data);
};