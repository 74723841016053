const prefix = type => `@LOANS/${type}`;

export const GET_EXECUTED_LOAN = prefix("GET_EXECUTED_LOAN");
export const GET_LOAN_PAYMENT = prefix("GET_LOAN_PAYMENT");
export const GET_LOAN_PAYMENTS = prefix("GET_LOAN_PAYMENTS");

export const ADD_LOAN_PAYMENT_NOTE = prefix("ADD_LOAN_PAYMENT_NOTE");
export const GET_LOAN_PAYMENT_NOTES = prefix("GET_LOAN_PAYMENT_NOTES");

export const CREATE_SERVICE_ENROLLMENT = prefix("CREATE_SERVICE_ENROLLMENT");
export const GET_SERVICE_ENROLLMENT = prefix("GET_SERVICE_ENROLLMENT");
