/*
 src/reducers/rootReducer.js
*/
import { combineReducers } from "redux";
import currentLoan from "./currentLoan";
import gifts from "./gifts";
import loans from "./loans";
import notification from "./notification";
import user from "./user";

export default combineReducers({
  loans,
  notification,
  user,
  gifts,
  currentLoan
});
