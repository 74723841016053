import { createTheme } from "@mui/material/styles";


// $brand-white: #ffffff;
// $brand-elephant: #cdc7cf;
// $brand-dusk: #4f566b;
// $brand-sienna: #e06a56;
// $brand-eggplant: #392330;
// $brand-black: #000000;
// $brand-blue: #52689e;
// $brand-green: #429d84;
// $brand-sky-blue: #e4ebfc;
// $homepage-light: #f9f9f9;
// $brand-light-green: #e9f1e8;

export const appTheme = createTheme({
    palette: {
        primary:
        {
            main: "#392330"
        },
        secondary:
        {
            main: "#52689e"
        },
        error:
        {
            main: "#e06a56"
        },
        warning:
        {
            main: "#e06a56"
        },
        info:
        {
            main: "#429d84"
        },
        success:
        {
            main: "#429d84"
        },
        text:
        {
            primary: "#392330",
            secondary: "#52689e",
            disabled: "#cdc7cf"
        },
        background:
        {
            paper: "#fdfdfd",
            default: "#ffffff"
        }
    },
    typography: {
        fontFamily: ['Poppins'],
        fontSize: "1rem",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,

    },
    spacing: 2,
});
