const prefix = type => `@USER/${type}`;

export const AUTHENTICATED = prefix("AUTHENTICATED");
export const CREATE_USER = prefix("CREATE_USER");
export const RESET_PASSWORD = prefix("RESET_PASSWORD");
export const SIGN_IN = prefix("SIGN_IN");
export const SIGN_IN_ERROR = prefix("SIGN_IN_ERROR");
export const SIGN_IN_SUCCESS = prefix("SIGN_IN_SUCCESS");
export const SIGN_OUT = prefix("SIGN_OUT");
export const UNAUTHENTICATED = prefix("UNAUTHENTICATED");
export const UPDATE_USER = prefix("UPDATE_USER");
export const UPDATE_USER_PROFILE = prefix("UPDATE_USER_PROFILE");