import { TRIGGER_NOTIFICATION } from "./types";

export const initialState = {
  error: false,
  message: "",
  show: false
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case TRIGGER_NOTIFICATION:
      return {
        ...state,
        error: payload.error,
        message: payload.message,
        show: true
      };
    default:
      return state;
  }
};

export default reducer;
