const prefix = type => `@LOANS/${type}`;

export const ACCEPT_TERMS_OF_SERVICE = prefix("ACCEPT_TERMS_OF_SERVICE");
export const CREATE_NEW_LOAN_REQUEST = prefix("CREATE_NEW_LOAN_REQUEST");
export const SIGN_LOAN_REQUEST = prefix("SIGN_LOAN_REQUEST");
export const FINALIZE_LOAN_REQUEST = prefix("FINALIZE_LOAN_REQUEST");
export const FRESH_LOAN_REQUEST = prefix("FRESH_LOAN_REQUEST");
export const GET_LOAN_REQUESTS = prefix("GET_LOAN_REQUESTS");
export const GET_LOAN_DOCUMENT = prefix("GET_LOAN_DOCUMENT");
export const GET_LOAN_REQUEST = prefix("GET_LOAN_REQUEST");
export const REJECT_REASON = prefix("REJECT_REASON");
export const REVIEWED_LOAN_REQUEST = prefix("REVIEWED_LOAN_REQUEST");
export const SEND_LOAN_TO_RECIPIENT = prefix("SEND_LOAN_TO_RECIPIENT");
export const UPDATE_LOAN_REQUEST = prefix("UPDATE_LOAN_REQUEST");
export const DELETE_LOAN_REQUEST = prefix("DELETE_LOAN_REQUEST");
