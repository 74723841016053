
import { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getUser } from "../../apis/auth";
import { selectUserAuth } from "../../state/user/selectors";

function RequireAuth(props) {
  const user = props.user;
  useEffect(() => {
    if (user && user.userAuthToken && user.auth === true) {
      getUser({ userAuthToken: user.userAuthToken });
    }
  }, [user]);

  const locationHistory = useLocation();
  const userAuth = props.userAuth;
  const userVerification = props.userVerification;
  const children = props.children;
  var redirectTo = "/";
  if ((userAuth === undefined) || (userAuth === false)) {
    redirectTo = "/signin";
  }
  if (userVerification === false) {
    redirectTo = "/verification";
  }
  return (userAuth && userVerification) ? children : <Navigate to={redirectTo} state={locationHistory} />;
}
export const mapStateToProps = state => ({
  user: state.user,
  userAuth: selectUserAuth(state),
  userVerification: state.user.hasVerifiedEmail
});

export default connect(mapStateToProps)(RequireAuth);
